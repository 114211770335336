import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class PageTitle extends Component {
    render() {
        return (
            <>
                <div className="d-sm-flex align-items-center justify-content-between mb-4 px-1">
                    <h1 className="h3 mb-0 text-gray-800">{this.props.Title}</h1>
                    {/* {
                        (this.props.Title === 'Dashboard') &&
                        <Link to={"#"} className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
                            <i className="fas fa-download fa-sm text-white-50"></i> Generate Report
                        </Link>
                    } */}
                </div>

            </>
        );
    }
}

export default PageTitle;