import React, { Component } from 'react';
import Sidebar from "../../components/common/Sidebar";
import Topbar from "../../components/common/Topbar";
import PageTitle from "../../components/home/PageTitle";
import Footer from '../../components/common/Footer';
import URL from '../../components/master/URL';

class URLPage extends Component {
    render() {
        return (
            <>
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">

                        <Topbar />

                        <div className="container-fluid">

                            <URL />

                        </div>

                    </div>

                    <Footer />

                </div>
            </>
        );
    }
}

export default URLPage;