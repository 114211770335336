import React, { Component } from 'react';

class CarSubBooking extends Component {
    render() {
        return (
            <>
                <form>
                    <div className="row g-3">
                        <div className="col-md-4">
                            <label className="form-label">Destination *</label>
                            <select name="destination" className="form-select">
                                <option value="">-- Select --</option>
                                <option value="B2B">B2B</option>
                                <option value="B2C">B2C</option>
                                <option value="Others">Others</option>
                            </select>
                        </div>
                        <div className="col-md-8">
                            <label className="form-label">Vendor Name *</label>
                            <select name="vendorName" className="form-select">
                                <option value="">-- Select --</option>
                                <option value="B2B">Subrata Haldar (+91 9830506353)</option>
                                <option value="B2C">Debkumar Ghosh (+91 9830506444)</option>
                            </select>
                        </div>
                        <div className="col-md-4">
                            <label className="form-label">Car Name *</label>
                            <select name="carName" className="form-select">
                                <option value="">-- Select --</option>
                                <option value="B2B">Innova</option>
                                <option value="B2C">Bolero</option>
                                <option value="Xylo">Xylo</option>
                            </select>
                        </div>
                        <div className="col-md-4">
                            <label className="form-label">Pick Up *</label>
                            <input type="text" name="pickUpLocation" className="form-control" required />
                        </div>
                        <div className="col-md-4">
                            <label className="form-label">Drop *</label>
                            <input type="text" name="dropLocation" className="form-control" required />
                        </div>                        
                        <div className="col-md-4">
                            <label className="form-label">Start Date *</label>
                            <input type="date" name="startDate" className="form-control" required />
                        </div>
                        <div className="col-md-4">
                            <label className="form-label">End Date *</label>
                            <input type="date" name="endDate" className="form-control" required />
                        </div>
                        <div className="col-md-4">
                            <label className="form-label">No. of Cars *</label>
                            <input type="text" name="noOfCars" className="form-control" />
                        </div>
                    </div>
                    <div className="card mb-1 mt-2">
                        <div className="card-head text-center">Vendor Payment</div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <label className="form-label">Vendor Payable *</label>
                                    <input type="text" name="vendorPayable" className="form-control" required />
                                </div>
                                <div className="col-md-4">
                                    <label className="form-label">Paid to Vendor</label>
                                    <input type="text" name="vendorPaid" className="form-control" disabled />
                                </div>
                                <div className="col-md-4">
                                    <label className="form-label">Due to Vendor</label>
                                    <input type="text" name="vendorDue" className="form-control" disabled />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </>
        );
    }
}

export default CarSubBooking;