import React from "react";
import { useTable, useSortBy, useFilters, usePagination } from "react-table";
import "bootstrap/dist/css/bootstrap.min.css";

// Default column filter for text-based filtering
const DefaultColumnFilter = ({ column: { filterValue, setFilter } }) => (
    <input
        value={filterValue || ""}
        onChange={(e) => setFilter(e.target.value || undefined)} // Set undefined to remove the filter entirely
        placeholder="Filter..."
        className="form-control form-control-sm"
    />
);

// Table component with pagination
const TableComponent = ({ columns, data }) => {
    const defaultColumn = React.useMemo(
        () => ({
            Filter: DefaultColumnFilter, // Apply the default filter to all columns
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page, // Instead of rows, use page for pagination
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            initialState: { pageIndex: 0, pageSize: 50 }, // Set initial pagination state
        },
        useFilters,
        useSortBy,
        usePagination // Enables pagination
    );

    return (
        <div className="table-responsive">
            <div className="pagination d-flex justify-content-between align-items-center mb-2">
                <div>
                    <button className="btn btn-sm btn-primary-border mr-2" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        <i className="fa-solid fa-backward-step">‌</i> First
                    </button>
                    <button className="btn btn-sm btn-primary-border mr-2" onClick={() => previousPage()} disabled={!canPreviousPage}>
                        <i className="fa-solid fa-caret-left">‌</i> Prev
                    </button>
                    <span className="mr-2">
                        Page <strong>{pageIndex + 1} of {pageOptions.length}</strong>
                    </span>
                    <button className="btn btn-sm btn-primary-border mr-2" onClick={() => nextPage()} disabled={!canNextPage}>
                        <i className="fa-solid fa-caret-right">‌</i> Next
                    </button>
                    <button className="btn btn-sm btn-primary-border mr-2" onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage}>
                        <i className="fa-solid fa-forward-step">‌</i> Last
                    </button>
                </div>
                {/* <div>
                    Page{" "}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>
                </div> */}
                <div>
                    <select
                        className="form-select form-select-sm"
                        value={pageSize}
                        onChange={(e) => setPageSize(Number(e.target.value))}
                    >
                        {[50, 100, 200].map((size) => (
                            <option key={size} value={size}>
                                Show {size}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <table
                className="table table-bordered table-striped table-condensed cf"
                {...getTableProps()}
            >
                <thead className="cf bg-primary text-white">
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    className="text-center"
                                >
                                    {column.render("Header")}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? " 🔽"
                                                : " 🔼"
                                            : ""}
                                    </span>
                                    <div>{column.canFilter ? column.render("Filter") : null}</div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                    <td {...cell.getCellProps()} className={cell.column.className || "text-center"}>
                                        {cell.render("Cell")}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default TableComponent;
