import React, { Component } from 'react';
import { registerAllModules } from "handsontable/registry";
import { Link, Redirect } from 'react-router-dom';
import ApiURL from "../../../api/ApiURL";
import axios from "axios";
import cogoToast from 'cogo-toast';
import LocalStorageHelper from '../../../LocalStorageHelper/LocalStorageHelper';
import PageTitle from '../../home/PageTitle';
import TableComponent from '../../common/TableComponent';

class BookingList extends Component {
    //constructor lifecycle event
    constructor(props) {
        super(props);
        //register Handsontable's modules
        registerAllModules();
        this.state = {
            redirectBookingStatus: false,
            data: [],
            columns: [],
        };
    }
    //componentDidMount lifecycle event
    componentDidMount() {
        window.scroll(0, 0);
        var _self = this;
        var userData = JSON.parse(LocalStorageHelper.getUser());
        if (userData !== null && userData.UserId > 0) {
            this.setState({
                UserId: userData.UserId,
                UserRole: userData.RoleName
            })
        }
        else {
            let winlocation = window.location.pathname;
            LocalStorageHelper.SetLastRedirectPath(winlocation);
            this.setState({ RedirectToLogin: true })
        }
        this.getAllBookingData();
        this.getAllDataColumns();
    }
    //Get all Booking Data
    getAllBookingData = () => {
        this.setState({
            data: [
                {
                    bookingNo: "THI/2025/1",
                    bookingDate: "04-01-2025",
                    customer: "Amit Dey",
                    mobile: "+91 9830506751",
                    destination: "North Bengal",
                    bookingType: "B2C",
                    payableAmount: 65000.0,
                    paidAmount: 25000.0,
                    dueAmount: 40000.0,
                },
                {
                    bookingNo: "THI/2025/2",
                    bookingDate: "01-01-2025",
                    customer: "Subrata Haldar",
                    mobile: "+91 9830506353",
                    destination: "Sundarban",
                    bookingType: "B2C",
                    payableAmount: 63000.0,
                    paidAmount: 25000.0,
                    dueAmount: 38000.0,
                },
                {
                    bookingNo: "THI/2025/3",
                    bookingDate: "27-12-2024",
                    customer: "Debjani Ghosh",
                    mobile: "+44 9830506321",
                    destination: "Sikkim",
                    bookingType: "B2C",
                    payableAmount: 61000.0,
                    paidAmount: 25000.0,
                    dueAmount: 36000.0,
                },
            ],
        })
    }
    //Get all columns
    getAllDataColumns = () => {
        this.setState({
            columns: [
                {
                    Header: "Select",
                    accessor: "select",
                    disableSortBy: true,
                    disableFilters: true,
                    Cell: ({ row }) => (
                        <div className="text-center" title="Select">
                            <i
                                className="fa-solid fa-pen-to-square"
                                style={{ cursor: "pointer" }}
                                onClick={() => this.handleEdit(row.original)}
                            ></i>
                        </div>
                    ),
                },
                { Header: "Booking No.", accessor: "bookingNo" },
                { Header: "Booking Date", accessor: "bookingDate" },
                { Header: "Customer", accessor: "customer" },
                { Header: "Mobile", accessor: "mobile" },
                { Header: "Destination", accessor: "destination" },
                { Header: "Booking Type", accessor: "bookingType" },
                { Header: "Payable Amount", accessor: "payableAmount" },
                { Header: "Paid Amount", accessor: "paidAmount" },
                { Header: "Due Amount", accessor: "dueAmount" },
            ],
        })
    }
    //Data Reload
    handleReload = () => { };
    //Handle Add new Record
    handleEdit = () => { this.setState({ redirectBookingStatus: true }) }
    //Redirect To Manage Booking Page
    redirectToBookingPage = () => {
        if (this.state.redirectBookingStatus === true) {
            return <Redirect to={'/bk'} />
        }
    }
    //render lifecycle event
    render() {
        const { data, columns } = this.state;
        return (
            <>
                <div className="container-fluid py-4 mt-6">
                    <div className="row">
                        <div className="col-sm-9 justify-content-start">
                            <PageTitle Title="Booking List" />
                        </div>
                        <div className="col-sm-3 text-right">
                            <button className="btn btn-outline-dark btn-sm px-3 mx-3" title="Refresh" onClick={this.handleReload}>
                                <i className="fa-solid fa-arrows-rotate"></i> Refresh
                            </button>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">

                            <TableComponent columns={columns} data={data} />

                        </div>
                    </div>
                </div>
                {this.redirectToBookingPage()}
            </>
        );
    }
}

export default BookingList;