import React, { Component } from 'react';
import ManageBooking from '../../components/transaction/Booking/ManageBooking';
import Topbar from '../../components/common/Topbar';
import Footer from '../../components/common/Footer';

class ManageBookingPage extends Component {
    render() {
        return (
            <>
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">

                        <Topbar />

                        <div className="container-fluid">

                            {/* <PageTitle Title="Manage Search Lead" /> */}
                            <ManageBooking />

                        </div>

                    </div>

                    <Footer />

                </div>
            </>
        );
    }
}

export default ManageBookingPage;