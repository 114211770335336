import React, { Component } from 'react';
import profileImage from '../../assets/images/undraw_profile.svg';
import { Link, Redirect } from 'react-router-dom';
import LocalStorageHelper from '../../LocalStorageHelper/LocalStorageHelper';


class Topbar extends Component {
    constructor() {
        super();
        this.state = {
            showProfileSubMenu: false,
            showMessageSubMenu: false,
            showNotiAlertSubMenu: false,
            redirectLoginStatus: false,
            UserId: 0,
            UserRole: '',
            displayName: ''
        }
    }

    //componentDidMount lifecycle event
    componentDidMount() {
        var userData = JSON.parse(LocalStorageHelper.getUser());
        if (userData !== null && userData.UserId > 0) {
            this.setState({
                UserId: userData.UserId,
                UserRole: userData.RoleName,
                displayName: userData.DisplayName
            })
        }
        else {
            let winlocation = window.location.pathname;
            LocalStorageHelper.SetLastRedirectPath(winlocation);
            this.setState({ redirectLoginStatus: true })
        }
    }



    showProfileSubMenu = (e) => {
        e.preventDefault();
        let profileSubMenu = this.state.showProfileSubMenu;
        this.setState({
            showProfileSubMenu: !profileSubMenu,
            showMessageSubMenu: false,
            showNotiAlertSubMenu: false
        })
    }

    showMessageSubMenu = (e) => {
        e.preventDefault();
        let messageSubMenu = this.state.showMessageSubMenu;
        this.setState({
            showProfileSubMenu: false,
            showMessageSubMenu: !messageSubMenu,
            showNotiAlertSubMenu: false
        })
    }

    showNotificationAlertSubMenu = (e) => {
        e.preventDefault();
        let notiSubMenu = this.state.showNotiAlertSubMenu;
        this.setState({
            showProfileSubMenu: false,
            showMessageSubMenu: false,
            showNotiAlertSubMenu: !notiSubMenu
        })
    }

    signOut = () => {
        LocalStorageHelper.removeUser();
        this.setState({
            redirectLoginStatus: true
        })
    }

    redirectToLoginPage = () => {
        if (this.state.redirectLoginStatus === true) {
            return <Redirect to={'/login'} />
        }
    }

    render() {
        return (
            <>
                <nav className="navbar fixed-top navbar-expand navbar-light bg-primary topbar static-top shadow">
                    <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                        <i className="fa fa-bars"></i>
                    </button>
                    <form
                        className="d-none d-sm-inline-block form-inline ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                        <div className="input-group">
                            <h5><b>TOURIST HUB INDIA</b></h5>
                        </div>
                    </form>

                    {/* Nav Menu Start */}

                    <nav ClassName="navbar navbar-expand-lg">
                        <div className="container-fluid">
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                    <li className="nav-item">
                                        <a className="nav-link active" aria-current="page" href="#"><i className="fa fa-home mr-2" aria-hidden="true"></i>
                                            Home</a>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Master
                                        </a>
                                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                            <li>
                                                <Link className="dropdown-item" to={"/url"}>Manage URL</Link>
                                            </li>
                                            <li><hr className="dropdown-divider" /></li>
                                            <li>
                                                <Link className="dropdown-item" to={"/tc"}>Manage Travel Circuit</Link>
                                            </li>
                                            <li><hr className="dropdown-divider" /></li>
                                            <li>
                                                <Link className="dropdown-item" to={"/spl"}>Manage Specialist</Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Transaction
                                        </a>
                                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                            <li>
                                                <Link className="dropdown-item" to={"/sl"}>Search Lead</Link>
                                            </li>
                                            <li><hr className="dropdown-divider"/></li>
                                            {/* <li>
                                                <Link className="dropdown-item" to={"/bk-list"}>Track Booking</Link>
                                            </li> */}
                                        </ul>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Report
                                        </a>
                                        {/* <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                            <li><a className="dropdown-item" href="#">Action</a></li>
                                        </ul> */}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>

                    {/* Nav Menu End */}

                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item dropdown no-arrow d-sm-none">
                            <Link className="nav-link dropdown-toggle" to={"/"} id="searchDropdown" role="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fas fa-search fa-fw"></i>
                            </Link>

                            <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
                                aria-labelledby="searchDropdown">
                                <form className="form-inline mr-auto w-100 navbar-search">
                                    <div className="input-group">
                                        <input type="text" className="form-control bg-light border-0 small"
                                            placeholder="Search for..." aria-label="Search"
                                            aria-describedby="basic-addon2" />
                                        <div className="input-group-append">
                                            <button className="btn btn-primary" type="button">
                                                <i className="fas fa-search fa-sm"></i>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </li>


                        {/* <li className="nav-item dropdown no-arrow mx-1">
                            <Link className="nav-link dropdown-toggle" to={"/"} id="alertsDropdown" role="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={this.showNotificationAlertSubMenu}>
                                <i className="fas fa-bell fa-fw"></i>
                                <span className="badge badge-danger badge-counter">3+</span>
                            </Link>

                            <div 
                                className={this.state.showNotiAlertSubMenu
                                    ? "dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in show"
                                    : "dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                }
                                aria-labelledby="alertsDropdown">
                                <h6 className="dropdown-header">
                                    Alerts Center
                                </h6>
                                <Link className="dropdown-item d-flex align-items-center" to={"/"}>
                                    <div className="mr-3">
                                        <div className="icon-circle bg-primary">
                                            <i className="fas fa-file-alt text-white"></i>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="small text-gray-500">December 12, 2019</div>
                                        <span className="font-weight-bold">Link new monthly report is ready to download!</span>
                                    </div>
                                </Link>
                                <Link className="dropdown-item d-flex align-items-center" to={"/"}>
                                    <div className="mr-3">
                                        <div className="icon-circle bg-success">
                                            <i className="fas fa-donate text-white"></i>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="small text-gray-500">December 7, 2019</div>
                                        &#8377;290.29 has been deposited into your account!
                                    </div>
                                </Link>
                                <Link className="dropdown-item d-flex align-items-center" to={"/"}>
                                    <div className="mr-3">
                                        <div className="icon-circle bg-warning">
                                            <i className="fas fa-exclamation-triangle text-white"></i>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="small text-gray-500">December 2, 2019</div>
                                        Spending Alert: We've noticed unusually high spending for your account.
                                    </div>
                                </Link>
                                <Link className="dropdown-item text-center small text-gray-500" to={"/"}>Show All Alerts</Link>
                            </div>
                        </li>

                        <li className="nav-item dropdown no-arrow mx-1">
                            <Link className="nav-link dropdown-toggle" to={"/"} id="messagesDropdown" role="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={this.showMessageSubMenu}>
                                <i className="fas fa-envelope fa-fw"></i>
                                <span className="badge badge-danger badge-counter">7</span>
                            </Link>

                            <div
                                className={this.state.showMessageSubMenu
                                    ? "dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in show"
                                    : "dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"}
                                aria-labelledby="messagesDropdown">
                                <h6 className="dropdown-header">
                                    Message Center
                                </h6>
                                <Link className="dropdown-item d-flex align-items-center" to={"/"}>
                                    <div className="dropdown-list-image mr-3">
                                        <img className="rounded-circle" src={profileImage1} alt="..." />
                                        <div className="status-indicator bg-success"></div>
                                    </div>
                                    <div className="font-weight-bold">
                                        <div className="text-truncate">Hi there! I am wondering if you can help me with Link
                                            problem I've been having.</div>
                                        <div className="small text-gray-500">Emily Fowler · 58m</div>
                                    </div>
                                </Link>
                                <Link className="dropdown-item d-flex align-items-center" to={"/"}>
                                    <div className="dropdown-list-image mr-3">
                                        <img className="rounded-circle" src={profileImage2} alt="..." />
                                        <div className="status-indicator"></div>
                                    </div>
                                    <div>
                                        <div className="text-truncate">I have the photos that you ordered last month, how
                                            would you like them sent to you?</div>
                                        <div className="small text-gray-500">Jae Chun · 1d</div>
                                    </div>
                                </Link>
                                <Link className="dropdown-item d-flex align-items-center" to={"/"}>
                                    <div className="dropdown-list-image mr-3">
                                        <img className="rounded-circle" src={profileImage3} alt="..." />
                                        <div className="status-indicator bg-warning"></div>
                                    </div>
                                    <div>
                                        <div className="text-truncate">Last month's report looks great, I am very happy with
                                            the progress so far, keep up the good work!</div>
                                        <div className="small text-gray-500">Morgan Alvarez · 2d</div>
                                    </div>
                                </Link>
                                <Link className="dropdown-item d-flex align-items-center" to={"/"}>
                                    <div className="dropdown-list-image mr-3">
                                        <img className="rounded-circle" src="https://source.unsplash.com/Mv9hjnEUHR4/60x60" alt="..." />
                                        <div className="status-indicator bg-success"></div>
                                    </div>
                                    <div>
                                        <div className="text-truncate">Am I Link good boy? The reason I ask is because someone
                                            told me that people say this to all dogs, even if they aren't good...</div>
                                        <div className="small text-gray-500">Chicken the Dog · 2w</div>
                                    </div>
                                </Link>
                                <Link className="dropdown-item text-center small text-gray-500" to={"/"}>Read More Messages</Link>
                            </div>
                        </li> */}


                        <div className="topbar-divider d-none d-sm-block"></div>

                        <li className="nav-item dropdown no-arrow">
                            <Link className="nav-link dropdown-toggle" to={"/"} id="userDropdown" role="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={this.showProfileSubMenu}>
                                <span className="mr-2 d-none d-lg-inline small">{this.state.displayName}</span>
                                <img className="img-profile rounded-circle" src={profileImage} alt="..." />
                            </Link>

                            <div
                                className={this.state.showProfileSubMenu
                                    ? "dropdown-menu dropdown-menu-right shadow animated--grow-in show"
                                    : "dropdown-menu dropdown-menu-right shadow animated--grow-in"}
                                aria-labelledby="userDropdown"
                            >
                                <Link className="dropdown-item" to={"/home"}>
                                    <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                                    Manage Profile
                                </Link>
                                <li><hr className="dropdown-divider" /></li>
                                <Link className="dropdown-item" to={"/home"}>
                                    <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                                    Settings
                                </Link>
                                <div className="dropdown-divider"></div>
                                <Link className="dropdown-item" to={"/forgotpwd"}>
                                    <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                                    Forgot Password
                                </Link>
                                <div className="dropdown-divider"></div>
                                <Link className="dropdown-item" to={"/"} onClick={this.signOut}>
                                    <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                    Logout
                                </Link>
                            </div>
                        </li>

                    </ul>

                </nav>
                {this.redirectToLoginPage()}
            </>
        );
    }
}

export default Topbar;