import React, { Component } from 'react';

class OtherSubBooking extends Component {
    render() {
        return (
            <>
                <form>
                    <div className="row g-3">
                        <div className="col-md-4">
                            <label className="form-label">Pirpose *</label>
                            <input type="text" name="purpose" className="form-control" required />
                        </div>                        
                        <div className="col-md-4">
                            <label className="form-label">Start Date *</label>
                            <input type="date" name="startDate" className="form-control" required />
                        </div>
                        <div className="col-md-4">
                            <label className="form-label">End Date *</label>
                            <input type="date" name="endDate" className="form-control" required />
                        </div>                        
                    </div>
                    <div className="card mb-1 mt-2">
                        <div className="card-head text-center">Vendor Payment</div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <label className="form-label">Vendor Payable *</label>
                                    <input type="text" name="vendorPayable" className="form-control" required />
                                </div>
                                <div className="col-md-4">
                                    <label className="form-label">Paid to Vendor</label>
                                    <input type="text" name="vendorPaid" className="form-control" disabled />
                                </div>
                                <div className="col-md-4">
                                    <label className="form-label">Due to Vendor</label>
                                    <input type="text" name="vendorDue" className="form-control" disabled />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </>
        );
    }
}

export default OtherSubBooking;