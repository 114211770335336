import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import PageTitle from '../../home/PageTitle';
import HotelSubBooking from '../SubBooking/HotelSubBooking';
import CarSubBooking from '../SubBooking/CarSubBooking';
import OtherSubBooking from '../SubBooking/OtherSubBooking';

class ManageBooking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 'customerInfo',
            showModal: false,
            subBookingType: '',
            redirectBookingListStatus: false,
        };
    }

    handleTabChange = (tab) => {
        this.setState({ activeTab: tab });
    };

    handleReload = () => {
        window.location.reload();
    };

    handleAddNew = (e) => {
        e.preventDefault();
        if (this.state.subBookingType === 'Hotel'
            || this.state.subBookingType === 'Car'
            || this.state.subBookingType === 'Other') {
            this.setState({ showModal: true });
        }
    };

    subBookingOnChangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => console.log(this.state.subBookingType))
    }

    openModal = () => this.setState({ showModal: true });

    closeModal = () => this.setState({ showModal: false });

    render() {
        const { showModal } = this.state;
        if (this.state.redirectBookingListStatus) {
            return <Redirect to="/bk" />;
        }

        const { activeTab } = this.state;

        return (
            <>
                <div className="container py-4 mt-6">
                    <div className="row mb-4">
                        <div className="col-sm-9 justify-content-start">
                            <PageTitle Title="Manage Booking" />
                        </div>
                    </div>

                    <div className="card mb-4">
                        <div className="card-body">
                            <div className="row mb-4">
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Booking No.</label>
                                        <div className="col-sm-9">
                                            <input type="text" className="form-control" name="bookingNo" placeHolder="Booking No." disabled />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Booking Date</label>
                                        <div className="col-sm-9">
                                            <input type="text" className="form-control" name="bookingDate" placeHolder="Booking Date" disabled />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Travel Circuit</label>
                                        <div className="col-sm-9">
                                            <input type="text" className="form-control" name="travelCircuit" placeHolder="Travel Circuit" disabled />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Payable Amt.</label>
                                        <div className="col-sm-9">
                                            <input type="text" className="form-control" name="payableAmount" placeHolder="Payable Amount" disabled />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Paid Amt.</label>
                                        <div className="col-sm-9">
                                            <input type="text" className="form-control" name="paidAmount" placeHolder="Paid Amount" disabled />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Due Amt.</label>
                                        <div className="col-sm-9">
                                            <input type="text" className="form-control" name="dueAmount" placeHolder="Due Amount" disabled />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ul className="nav nav-tabs" role="tablist">
                        <li className="nav-item">
                            <button
                                className={`nav-link ${activeTab === 'customerInfo' ? 'active' : ''}`}
                                onClick={() => this.handleTabChange('customerInfo')}
                            >
                                Customer Details
                            </button>
                        </li>
                        <li className="nav-item">
                            <button
                                className={`nav-link ${activeTab === 'bookingInfo' ? 'active' : ''}`}
                                onClick={() => this.handleTabChange('bookingInfo')}
                            >
                                Booking Details
                            </button>
                        </li>
                        <li className="nav-item">
                            <button
                                className={`nav-link ${activeTab === 'subBookingInfo' ? 'active' : ''}`}
                                onClick={() => this.handleTabChange('subBookingInfo')}
                            >
                                Sub Booking Details
                            </button>
                        </li>
                        <li className="nav-item">
                            <button
                                className={`nav-link ${activeTab === 'customerPaymentInfo' ? 'active' : ''}`}
                                onClick={() => this.handleTabChange('customerPaymentInfo')}
                            >
                                Customer Payment History
                            </button>
                        </li>
                    </ul>

                    <div className="tab-content mt-3">
                        {activeTab === 'customerInfo' && (
                            <div className="tab-pane fade show active">
                                <form>
                                    <div className="row g-3">
                                        <div className="col-md-4">
                                            <label className="form-label">First Name</label>
                                            <input type="text" className="form-control" required />
                                        </div>
                                        <div className="col-md-4">
                                            <label className="form-label">Last Name</label>
                                            <input type="text" className="form-control" required />
                                        </div>
                                        <div className="col-md-4">
                                            <label className="form-label">Travel Circuit</label>
                                            <select className="form-select">
                                                <option value="B2B">B2B</option>
                                                <option value="B2C">B2C</option>
                                                <option value="Others">Others</option>
                                            </select>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="form-label">Email</label>
                                            <input type="email" className="form-control" required />
                                        </div>
                                        <div className="col-md-4">
                                            <label className="form-label">Mobile No</label>
                                            <input type="text" className="form-control" required />
                                        </div>
                                        <div className="col-md-4">
                                            <label className="form-label">Booking Type</label>
                                            <select className="form-select">
                                                <option value="B2B">B2B</option>
                                                <option value="B2C">B2C</option>
                                                <option value="Others">Others</option>
                                            </select>
                                        </div>
                                        <div className="col-md-12">
                                            <label className="form-label">Address</label>
                                            <textarea className="form-control" rows="2"></textarea>
                                        </div>
                                        <div className="col-md-12 text-end">
                                            <button type="submit" className="btn btn-outline-primary">
                                                <i className="fa-solid fa-save"></i> Save
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )}

                        {activeTab === 'bookingInfo' && (
                            <div className="tab-pane fade show active">
                                <form>
                                    <div className="row g-3">
                                        <div className="col-md-6">
                                            <label className="form-label">Journey Start Point</label>
                                            <input type="text" className="form-control" required />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-label">Journey End Point</label>
                                            <input type="text" className="form-control" required />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-label">Start Date</label>
                                            <input type="date" className="form-control" required />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-label">End Date</label>
                                            <input type="date" className="form-control" required />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-label">No of Adults</label>
                                            <select className="form-select">
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-label">No of Children</label>
                                            <select className="form-select">
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                            </select>
                                        </div>
                                        <div className="col-md-12">
                                            <label className="form-label">Booked Date</label>
                                            <input type="date" className="form-control" required />
                                        </div>
                                        <div className="col-md-12 text-end">
                                            <button type="submit" className="btn btn-outline-primary">
                                                <i className="fa-solid fa-save"></i> Save
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )}

                        {activeTab === 'subBookingInfo' && (
                            <div className="tab-pane fade show active">
                                <div className="container">
                                    <div className='row mb-3'>
                                        <div className="input-group col-md-4 ml-1">
                                            <select
                                                className="form-select form-select-sm"
                                                name="subBookingType"
                                                onChange={this.subBookingOnChangeHandler}
                                            >
                                                <option value="">-- Select --</option>
                                                <option value="Hotel">Hotel</option>
                                                <option value="Car">Car</option>
                                                <option value="Other">Other</option>
                                            </select>
                                            <div className="input-group-append">
                                                <button className="btn btn-outline-primary btn-sm" title="Add Sub-Booking" onClick={this.handleAddNew}>
                                                    <i className="fas fa-plus"></i> Add Sub-Booking
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            {/* grid table */}
                                            <div id="no-more-tables" className="tableContainer" >
                                                <table className="table table-bordered table-striped table-condensed cf" >
                                                    <thead className="cf bg-primary text-white">
                                                        <tr>
                                                            <th className="text-center">Select</th>
                                                            <th className="text-center">Sub-Booking #</th>
                                                            <th className="text-center">Sub-Booking Type</th>
                                                            <th className="text-center">Vendor</th>
                                                            <th className="text-center">Location</th>
                                                            <th className="text-center">Vendor Payable Amt.</th>
                                                            <th className="text-center">Vendor Paid Amt.</th>
                                                            <th className="text-center">Vendor Due Amt.</th>
                                                            {/* <th className="text-center">Active</th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-center">
                                                                <button
                                                                    className="btn btn-primary btn-sm text-white fa-sx"
                                                                    onClick={() => this.openModal()}>
                                                                    <i className="fa-solid fa-pen-to-square fa-sx"></i>
                                                                </button>
                                                            </td>
                                                            <td className="text-center">THI/2025/1/H/1</td>
                                                            <td className="text-center">Hotel</td>
                                                            <td className="text-center">Sonar Bangla</td>
                                                            <td className="text-center">North Bengal</td>
                                                            <td className="text-right">60000.00</td>
                                                            <td className="text-right">20000.00</td>
                                                            <td className="text-right">40000.00</td>
                                                            {/* <td className="text-center">
                                                                <i className="fa-regular fa-square-check"></i>
                                                            </td> */}
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {activeTab === 'customerPaymentInfo' && (
                            <div className="tab-pane fade show active">
                                <div className="container">
                                    <div className='row mb-3'>
                                        <div className="input-group col-md-4 ml-1">
                                            <button className="btn btn-outline-primary btn-sm" title="Add Payment" >
                                                <i className="fas fa-plus"></i> Add Payment
                                            </button>

                                            <button className="btn btn-outline-dark btn-sm  mx-2" title="Refresh Payment" onClick={this.handleReload}>
                                                <i className="fas fa-sync-alt"></i> Refresh Payment
                                            </button>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            {/* grid table */}
                                            <div id="no-more-tables" className="tableContainer" >
                                                <table className="table table-bordered table-striped table-condensed cf" >
                                                    <thead className="cf bg-primary text-white">
                                                        <tr>
                                                            <th className="text-center">Select</th>
                                                            <th className="text-center">Payment Date</th>
                                                            <th className="text-center">Paid Amount</th>
                                                            <th className="text-center">Payment Mode</th>
                                                            <th className="text-center">MR. No.</th>
                                                            <th className="text-center">Active</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-center">
                                                                <button
                                                                    className="btn btn-primary btn-sm text-white fa-sx"
                                                                    onClick={() => this.openModal()}>
                                                                    <i className="fa-solid fa-pen-to-square fa-sx"></i>
                                                                </button>
                                                            </td>
                                                            <td className="text-center">01-01-2025</td>
                                                            <td className="text-center">15000.00</td>
                                                            <td className="text-center">NEFT</td>
                                                            <td className="text-center">MR001</td>
                                                            <td className="text-center">Active</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">
                                                                <button
                                                                    className="btn btn-primary btn-sm text-white fa-sx"
                                                                    onClick={() => this.openModal()}>
                                                                    <i className="fa-solid fa-pen-to-square fa-sx"></i>
                                                                </button>
                                                            </td>
                                                            <td className="text-center">04-01-2025</td>
                                                            <td className="text-center">10000.00</td>
                                                            <td className="text-center">UPI</td>
                                                            <td className="text-center">MR002</td>
                                                            <td className="text-center">Active</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {/* <!----------------- Hotel modal section----------------> */}
                <div className={showModal === true ? "modal show-modal  modalWrapper" : "modal"}
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h3 className="modal-title">Manage {this.state.subBookingType} Sub-Booking</h3>
                                <i className="fa-regular fa-circle-xmark text-danger" onClick={this.closeModal}></i>
                            </div>
                            <div className="modal-body">
                                <div className="row ">
                                    {
                                        this.state.subBookingType === 'Hotel'
                                            ? <HotelSubBooking />
                                            : this.state.subBookingType === 'Car'
                                            ? <CarSubBooking />
                                            : <OtherSubBooking />
                                    }
                                </div>
                            </div>
                            <div className="modal-footer my-3">
                                <button className="btn btn-success button-box-shadow">
                                    <i className="fa-solid fa-circle-check"></i> Save
                                </button>

                                <button className="btn btn-secondary button-box-shadow" onClick={this.closeModal}>
                                    <i className="fa-solid fa-circle-xmark"></i> Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!----------------- Other modal section----------------> */}


            </>
        );
    }
}

export default ManageBooking;
