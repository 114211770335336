import React, { Component } from 'react';
import axios from 'axios';
import ApiURL from "../../api/ApiURL";
import cogoToast from "cogo-toast";
import { registerAllModules } from "handsontable/registry";
import LocalStorageHelper from "../../LocalStorageHelper/LocalStorageHelper";
import "handsontable/dist/handsontable.full.min.css";
import "../../assets/css/custom.css";
import PageTitle from "../home/PageTitle";
import TableComponent from '../common/TableComponent';


export default class SpecialList extends Component {
  //constructor lifecycle event
  constructor(props) {
    super(props);
    //register Handsontable's modules
    registerAllModules();
    this.state = {
      showModal: false,
      showDeleteWarnModal: false,
      SPLData: [],
      columns: [],
      splId: 0,
      specialistName: '',
      mobile: '',
      email: '',
      userId: 0,
      userRole: '',
      isActive: false
    };
  }
  //componentDidMount lifecycle event
  componentDidMount() {
    window.scroll(0, 0);
    var userData = JSON.parse(LocalStorageHelper.getUser());
    if (userData !== null && userData.UserId > 0) {
      this.setState({
        userId: userData.UserId,
        userRole: userData.RoleName,
        displayName: userData.DisplayName
      })
    }
    this.getAllSPLData();
    this.getAllDataColumns();
  }
  //Data Reload
  handleReload = () => this.getAllSPLData();
  //Reset all input fields into the modal
  resetInputFields = () => {
    this.setState({
      showModal: false,
      showDeleteWarnModal: false,
      splId: 0,
      specialistName: "",
      mobile: "",
      email: "",
      isActive: false
    });
  }
  //Open modal
  openModal = () => this.setState({ showModal: true });
  //Close modal
  closeModal = () => this.setState({ showModal: false });
  //Open Delete warning modal
  openWarnModal = () => this.setState({ showDeleteWarnModal: true });
  //Close Delete warning modal
  closeWarnModal = () => this.setState({ showDeleteWarnModal: false });
  //OnChangeHandler
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }
  //Get all data
  getAllSPLData = () => {
    axios
      .get(ApiURL.GetAllSPL)
      .then((response) => {
        let respData = response.data;
        if (respData.StatusCode === 200) {
          var dataResult = respData.DataResult;
          this.setState({ SPLData: dataResult });
        }
        else if (respData.StatusCode === 500) {
          cogoToast.error("Internal Server Error ! Please Try Again.", { position: "bottom-center" });
        }
      })
      .catch((error) => {
        cogoToast.error("Request Failed! Please Try Again.", { position: "bottom-center" });
      });
  }
  //Get all columns
  getAllDataColumns = () => {
    this.setState({
      columns: [
        {
          Header: "Select",
          accessor: "select",
          disableSortBy: true,
          disableFilters: true,
          Cell: ({ row }) => (
            <div className="text-center" title="Select">
              <i
                className="fa-solid fa-pen-to-square"
                style={{ cursor: "pointer" }}
                onClick={() => this.getDataItemById(row.original.SpecialistId)}
              ></i>
            </div>
          ),
        },
        { Header: "Specialist", accessor: "SpecialistName", className: "text-left" },
        { Header: "Mobile", accessor: "Mobile", className: "text-center" },
        { Header: "Email", accessor: "Email", className: "text-left" },
        {
          Header: "Active",
          accessor: "IsActive",
          disableSortBy: true,
          disableFilters: true,
          Cell: ({ value }) => (
            <div className="text-center">
              {value ? (
                <i className="fa-regular fa-square-check"></i>
              ) : (
                <i className="fa-regular fa-square-uncheck"></i>
              )}
            </div>
          ),
        },
      ],
    })
  }
  //Get data by Id
  getDataItemById = (keyData) => {
    var self = this;
    axios
      .get(ApiURL.GetSPLById(keyData))
      .then((response) => {
        let respData = response.data;
        if (respData.StatusCode === 200) {
          var dataResult = respData.DataResult;
          self.setState({
            splId: dataResult.SpecialistId,
            specialistName: dataResult.SpecialistName,
            mobile: dataResult.Mobile,
            email: dataResult.Email,
            isActive: dataResult.IsActive,
            showModal: true,
          });
        }
        else if (respData.StatusCode === 500) {
          cogoToast.error("Internal Server Error ! Please Try Again.", { position: "bottom-center" });
        }
      })
      .catch((error) => {
        cogoToast.error("Request Failed! Please Try Again.", { position: "bottom-center" });
      });
  }
  //Handle Add new Record
  handleAddNew = () => {
    this.resetInputFields();
    this.openModal();
  }
  //Delete data by Id
  handleDelete = () => {
    let param = {
      SpecialistId: this.state.splId,
      IsActive: false
    };

    axios.post(ApiURL.DeleteSPL, param)
      .then((response) => {
        let respData = response.data;
        if (respData.StatusCode === 200) {
          cogoToast.success("Specialist Deleted Successfully!", { position: "bottom-center" });
          this.resetInputFields();
          this.closeWarnModal();
          this.getAllSPLData();
        }
        else if (respData.StatusCode === 500) {
          cogoToast.error("Internal Server Error ! Please Try Again.", { position: "bottom-center" });
        }
      })
      .catch((error) => {
        cogoToast.error("Request Failed! Please Try Again.", { position: "bottom-center" });
      });
  }
  //save data
  handleSubmit = (e) => {
    e.preventDefault();
    //set blank validation
    if (!this.state.specialistName || !this.state.mobile || !this.state.email) {
      cogoToast.error("Please fill all required fields.", { position: "bottom-center" });
      return;
    }

    let param = {
      SpecialistId: this.state.splId,
      specialistName: this.state.specialistName,
      mobile: this.state.mobile,
      email: this.state.email,
      isActive: this.state.isActive
    };
    axios
      .post(ApiURL.SaveSPL, param)
      .then((response) => {
        console.log(response);
        let respData = response.data;
        if (respData.StatusCode === 200) {
          cogoToast.success("Specialist Saved Successfully!", { position: "bottom-center" });
          this.resetInputFields();
          this.getAllSPLData();
        }
        else if (respData.StatusCode === 500) {
          cogoToast.error("Internal Server Error ! Please Try Again.", { position: "bottom-center" });
        }
      })
      .catch((error) => {
        cogoToast.error("Request Failed! Please Try Again.", { position: "bottom-center" });
      });
  }
  //render lifecycle event.
  render() {
    var self = this;
    const { showModal, splId, showDeleteWarnModal, SPLData, columns } = self.state;
    return (
      <>
        {/*--------Start main content---------*/}
        <div className="container-fluid py-4 mt-6">
          <div className="row">
            <div className="col-sm-9 justify-content-start">
              <PageTitle Title="Manage Specialist" />
            </div>
            <div className="col-sm-3 text-right">
              <button className="btn btn-outline-dark btn-sm px-3 mx-3" title="Refresh" onClick={this.handleReload}>
                <i className="fa-solid fa-arrows-rotate"></i> Refresh
              </button>
              <button className="btn btn-outline-primary btn-sm px-3 cursor-pointer" title="Add" onClick={this.handleAddNew}>
                <i className="fa-solid fa-plus"></i> Add
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">

              <TableComponent columns={columns} data={SPLData} />

            </div>
          </div>
        </div>

        {/* <!----------------- modal section----------------> */}
        <div className={showModal === true ? "modal show-modal  modalWrapper" : "modal"} tabIndex="-1">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title ">{splId <= 0 ? `Add Specialist` : `Update Specialist`}</h1>
                <i className="fa-regular fa-circle-xmark text-danger" data-bs-dismiss="modal" onClick={this.closeModal}></i>
              </div>
              <div className="modal-body">
                <div className="row ">
                  <div className="col">
                    <label htmlFor="splname" className="form-label text-sm" style={{ fontSize: "15px" }}>Specialist Name *</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Specialist Name"
                      name="specialistName"
                      value={this.state.specialistName}
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                  <div className="col">
                    <label htmlFor="mobile" className="form-label text-sm" style={{ fontSize: "15px" }}>Mobile No. * </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Mobile No."
                      name="mobile"
                      value={this.state.mobile}
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                  <div className="col">
                    <label htmlFor="email" className="form-label text-sm" style={{ fontSize: "15px" }}>Email Id * </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                      requited
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer my-3">
                <button
                  type="submit"
                  className="btn btn-success button-box-shadow"
                  onClick={this.handleSubmit}
                >
                  <i class="fa-regular fa-floppy-disk"></i> Save
                </button>
                {
                  this.state.userRole === 'admin' &&
                  <button
                    type="button"
                    className={`btn btn-danger button-box-shadow curser-pointer ${splId > 0 ? '' : 'disabled'}`}
                    onClick={() => { splId > 0 && this.openWarnModal() }}
                  >
                    <i className="fa-solid fa-trash-can"></i> Delete
                  </button>
                }
                <button
                  type="button"
                  className="btn btn-secondary button-box-shadow"
                  data-bs-dismiss="modal"
                  onClick={this.closeModal}
                >
                  <i className="fa-solid fa-circle-xmark"></i> Close
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* <!------DELETE Warning Modal--------> */}
        <div className={showDeleteWarnModal === true ? "modal show-modal" : "modal"}>
          <div className="modal-dialog modal-dialog-centered modal-md ">
            <div className="modal-content button-box-shadow">
              <div className="modal-body">
                <div className="alert alert-warning d-flex align-items-center" role="alert" >
                  <i className="fa-solid fa-triangle-exclamation"></i>&nbsp;
                  <div><strong>Are you sure</strong>, you want to <strong>Delete </strong> it?</div>
                </div>
              </div>
              <div className="modal-footer ">
                <button
                  type="button"
                  className="btn btn-danger button-box-shadow btn-sm"
                  onClick={this.handleDelete}
                >
                  <i className="fa-solid fa-trash-can"></i> Delete
                </button>

                <button
                  type="button"
                  className="btn btn-secondary button-box-shadow btn-sm"
                  data-bs-dismiss="modal"
                  onClick={this.closeWarnModal}
                >
                  <i className="fa-solid fa-circle-xmark"></i> Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
