import React, { Component } from 'react';

class HotelSubBooking extends Component {
    render() {
        return (
            <>
                <form>
                    <div className="row g-3">
                        <div className="col-md-4">
                            <label className="form-label">Destination *</label>
                            <select name="destination" className="form-select">
                                <option value="">-- Select --</option>
                                <option value="B2B">B2B</option>
                                <option value="B2C">B2C</option>
                                <option value="Others">Others</option>
                            </select>
                        </div>
                        <div className="col-md-4">
                            <label className="form-label">Hotel Name *</label>
                            <select name="hotelName" className="form-select">
                                <option value="">-- Select --</option>
                                <option value="1">Sonar Bangla</option>
                                <option value="2">Larika</option>
                                <option value="3">Hotel Charu Palace</option>
                            </select>
                        </div>
                        <div className="col-md-4">
                            <label className="form-label">Room Category *</label>
                            <select name="roomCategory" className="form-select">
                                <option value="">-- Select --</option>
                                <option value="B2B">B2B</option>
                                <option value="B2C">B2C</option>
                                <option value="Others">Others</option>
                            </select>
                        </div>
                        <div className="col-md-4">
                            <label className="form-label">No. of Rooms *</label>
                            <input type="text" name="noOfRooms" className="form-control" required />
                        </div>
                        <div className="col-md-4">
                            <label className="form-label">No. of EB *</label>
                            <input type="text" name="noOfEB" className="form-control" required />
                        </div>
                        <div className="col-md-4">
                            <label className="form-label">No. of CNB *</label>
                            <input type="text" name="noOfCNB" className="form-control" required />
                        </div>
                        <div className="col-md-4">
                            <label className="form-label">Meal Plan *</label>
                            <select className="form-select">
                                <option value="">-- Select --</option>
                                <option value="EP">EP</option>
                                <option value="CP">CP</option>
                                <option value="MAP">MAP</option>
                                <option value="APAI">APAI</option>
                            </select>
                        </div>
                        <div className="col-md-4">
                            <label className="form-label">Check In *</label>
                            <input type="date" name="checkIn" className="form-control" required />
                        </div>
                        <div className="col-md-4">
                            <label className="form-label">Check Out *</label>
                            <input type="date" name="checkOut" className="form-control" required />
                        </div>
                        <div className="col-md-4">
                            <label className="form-label">No. of Days</label>
                            <input type="text" name="noOfDays" className="form-control" disabled />
                        </div>
                    </div>
                    <div className="card mb-1 mt-2">
                        <div className="card-head text-center">Vendor Payment</div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <label className="form-label">Vendor Payable *</label>
                                    <input type="text" name="vendorPayable" className="form-control" required />
                                </div>
                                <div className="col-md-4">
                                    <label className="form-label">Paid to Vendor</label>
                                    <input type="text" name="vendorPaid" className="form-control" disabled />
                                </div>
                                <div className="col-md-4">
                                    <label className="form-label">Due to Vendor</label>
                                    <input type="text" name="vendorDue" className="form-control" disabled />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </>
        );
    }
}

export default HotelSubBooking;